// Dashboard.jsx
import React from 'react';
import Sidebar from './Sidebar';


function Header() {
  return (
    <div className='sideb'>
      
      
          <Sidebar />
        
     
      
    </div>
  );
};

export default Header;
