// Dashboard.jsx
import React from 'react';
import Header from './Header';
import Body from './Body';
import Hero from './hero';
import Footer from './Footer';
import Sidebar from './Sidebar';

function Dashboard() {
  return (
    <div>
      <Hero />
      {/* <Footer /> */}
    
     
      
    </div>
  );
};

export default Dashboard;
