import React from "react";
import AppRouter from './AppRouter';
//import 'bootstrap/dist/css/bootstrap.min.css';



function App(){



    return (
       <div>
             <AppRouter />
    </div>
    )
}

export default App;